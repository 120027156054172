@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Urbanist:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
@font-face {
  font-family: Malgun;
  src: url("./malgun.woff");
}
:root {
  --primary--color: #c7303c;
  --secondary--color: #00437b;
  --anim-offset: 150;
  --number--font: "Libre Baskerville", serif;
}
odd

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  vertical-align: middle;
}

p {
  line-height: 1.8;
  color: #000000bf;
  margin: 0;
}

h1,
h2,
h3,
h5,
h4,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0;
  color: #101010;
}

ul {
  margin-bottom: 0;
}
a,
a:hover,
a:focus {
  text-decoration: none;
}

body {
  user-select: none;
  font-family: "Urbanist", sans-serif;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.logo img {
  padding: 15px 0;
  width: 200px;
}

.navbar > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar > ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  list-style: none;
}

.main-navigation > div > div {
  align-self: stretch;
  display: flex;
  align-items: center;
}

.navbar > ul {
  display: flex;
  justify-content: space-between;
}

.navbar > ul > li {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s linear;
}

.navbar > ul > li > a {
  color: #111;
  font-size: 16px;
  display: block;
  padding: 15px 0;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
}

.navbar > ul > li > a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background: var(--primary--color);
  transition: linear 0.3s all;
  -webkit-transition: linear 0.3s all;
  -moz-transition: linear 0.3s all;
  -ms-transition: linear 0.3s all;
  -o-transition: linear 0.3s all;
}

.navbar > ul > li > a:hover {
  color: var(--primary--color);
}

.navbar > ul > li > a:hover::before,
.navbar > ul > li > a.active::before {
  width: 100%;
  transition: linear 0.3s all;
  -webkit-transition: linear 0.3s all;
  -moz-transition: linear 0.3s all;
  -ms-transition: linear 0.3s all;
  -o-transition: linear 0.3s all;
}
.navbar > ul > li:not(:first-child) {
  margin: 0 0 0 35px;
}
.cta--btns > button:not(.btn) {
  color: var(--secondary--color);
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: none;
  outline: none;
}
.cta--btns > .btn:not(:first-child) {
  margin-left: 10px;
}
.btn {
  position: relative;
  background: var(--secondary--color);
  display: inline-block;
  padding: 12px 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  border: 0;
  box-shadow: inset 0 0 0 0 var(--primary--color);
  transition: ease-out 0.5s;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  -ms-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.btn.fw {
  width: 100%;
}
.btn:hover {
  background: var(--primary--color);
  color: #fff;
  box-shadow: inset 0 -100px 0 0 var(--primary--color);
}
.chapter-item.even
.btn:active {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}
.btn.btn-disabled {
  cursor: not-allowed;
}
.banner__section {
  position: relative;
}

.banner--text {
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.banner--text span {
  font-size: 20px;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
}

.banner--text h1 {
  margin-top: 8px;
  font-size: 52px;
  font-weight: 800;
  background: linear-gradient(
    90deg,
    rgba(220, 30, 61, 0.9) 0%,
    rgb(33, 65, 138) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  line-height: 1.2;
  width: 50%;
}

.banner--text p {
  margin-top: 25px;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #676767;
  width: 60%;
}
.downloadBtn {
  margin-top: 25px;
}
.downloadBtn span {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: var(--secondary--color);
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
}

.downloadBtn > a {
  display: inline-block;
  margin-right: 10px;
}

.downloadBtn > a img {
  width: 200px;
}
.banner--img {
  position: relative;
  height: 100%;
}
.banner--img .mainimg {
  z-index: 4;
}
.featuesBtn {
  margin-top: 15px;
}

.featuesBtn > span {
  font-size: 17px;
  font-weight: 500;
  color: #111;
  margin-right: 15px;
}
.section {
  padding: 75px 0;
}
.section-t {
  padding-top: 75px;
}

.services-section {
  background-color: rgba(0, 67, 123, 0.1);
}
.section-head {
  margin-bottom: 30px;
}
.section-head > span {
  color: #00437b;
  font-weight: 600;
}
.section-head span.lessopacity {
  color: #ffffff7c;
}
.section-head h2 {
  font-size: 40px;
  font-weight: bold;
  color: #0d121f;
  line-height: 50px;
}
.section-head h2.white {
  color: #fff;
}
.section-head p {
  font-size: 18px;
  font-weight: 500;
  color: #596780;
  margin-bottom: 15px;
  line-height: 28px;
}

.about__section .section-head {
  margin-bottom: 15px;
}

.about__section {
  position: relative;
  z-index: 10;
}

.about--content {
  padding-left: 35px;
}

.about--content p {
  font-size: 18px;
  color: #5d6169;
  line-height: 1.8;
  margin-bottom: 25px;
}

.testimonials--content {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.testimonials--content h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 26px;
}

.carousel-footer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e9edf2;
  display: flex;
  align-items: center;
}

.carousel-footer img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.carousel-author {
  margin-left: 15px;
}

.carousel-author h4 {
  font-family: "Urbanist", sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.carousel-author span {
  font-size: 14px;
  color: #01050c;
}

.slick-arrow {
  position: absolute;
  bottom: -65px !important;
  top: auto;
  transform: translate(50%, 0);
  left: 50%;
  right: 0;
  width: 45px;
  height: 45px;
  background: #ffffff1a;
  display: block;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.slick-prev::before,
.slick-next::before {
  color: #fff !important;
  font-family: none;
}

.slick-prev {
  left: 46% !important;
}

.testimonial-section {
  background-color: #00437b;
  padding-bottom: 100px !important;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: var(--primary--color);
}

.accordion-button {
  padding: 0;
  border: none;
}

.accordion-item {
  padding: 15px 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-left: none;
  border-right: none;
}

.faq-section .accordion-header .accordion-button {
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
}

.faq-section .accordion-header .accordion-button:hover {
  color: var(--secondary--color);
}

.accordion-button:not(.collapsed) {
  color: var(--secondary--color);
  background: transparent;
  box-shadow: none;
}

.accordion-item {
  border: 0;
  border-bottom: 1px solid #eee;
}
.accordion-item:last-child {
  border: 0;
}
.faq-section .accordion-body {
  line-height: 1.8;
  color: #5e6163;
  font-size: 18px;
}
button:focus:not(:focus-visible) {
  outline: 0;
  border: none;
  box-shadow: none;
}
.download__app {
  position: relative;
  padding: 0;
}
.download__app.active > img {
  opacity: 1;
  transform: translateY(0);
}

.modalContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  max-height: 100vh;
  overflow-y: auto;
  padding: 50px 0;
}
.modalbody .closebutton {
  position: absolute;
  top: 25px;
  padding: 5px;
  cursor: pointer;
  z-index: 9999;
  right: 35px;
}
.modalbody {
  margin: 0 auto;
  padding: 45px;
  position: relative;
  background-color: #fff;
  border-radius: 14px;
}

.modalbody.sm {
  max-width: 440px;
}

.modalbody.lg {
  max-width: 500px;
}

.loginCustom {
  margin-top: 15px;
}

.termsCondions {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.defaultInputs h2 {
  font-size: 28px;
  font-weight: bold;
  color: var(--secondary--color);
}
.defaultInputs > p {
  font-size: 15px;
  font-weight: lighter;
  color: #010814;
  line-height: 22px;
  margin: 15px 0 20px 0;
}

.defaultInputs input:not([type="submit"]),
.defaultInputs input:not([type="checkbox"]) {
  border: none;
  width: 100%;
  outline: none;
  background-color: transparent;
  border: 1px solid #ddd;
  padding: 13px 15px;
  font-size: 15px;
  color: #111;
  font-weight: 400;
  border-radius: 15px;
}
.defaultInputs input[type="submit"],
.defaultInputs button {
  width: 100%;
  border: none;
  outline: none;
  padding: 12px 0;
  color: #fff;
  font-weight: 600;
  background-color: var(--secondary--color);
  line-height: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.defaultInputs input[type="checkbox"] {
  width: fit-content;
  margin-right: 5px;
}

.defaultInputs label {
  width: 100%;
  margin-bottom: 15px;
}
.formoptions {
  text-align: right;
  padding: 10px 0;
}
.formoptions p {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  cursor: pointer;
}
.orloginwith {
  position: relative;
  text-align: center;
  padding: 25px 0;
}
.orloginwith::after {
  content: " ";
  position: absolute;
  width: 100%;
  left: 0;
  display: inline-block;
  top: 50%;
  height: 1px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.1);
}
.orloginwith p {
  position: relative;
  z-index: 5;
  padding: 0 15px;
  display: inline-block;
  font-size: 15px;
  color: #444;
  background-color: #fff;
}
.defaultInputs label.password-container {
  position: relative;
}
.defaultInputs label.password-container i {
  position: absolute;
  top: 50%;
  cursor: pointer;
  right: 15px;
  color: #777;
  transform: translateY(-50%);
}

.loginForm label:not(:last-child) {
  margin-bottom: 10px;
}

.eachlogin-btn {
  cursor: pointer;
  width: 100%;
  display: block;
  margin-bottom: 13px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #bbb;
  padding: 12px 10px;
}
.eachlogin-btn img {
  margin-right: 5px;
  height: 21px;
  width: 21px;
}

.eachlogin-btn.google-login {
  background: transparent;
  border: 1px solid #bbb;
  border-radius: 10px;
  margin-bottom: 13px;
}
.eachlogin-btn.google-login span {
  color: #00437b;
}
.eachlogin-btn span {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
}
.eachlogin-btn.facebook-login {
  background-color: #00437b;
}
.eachlogin-btn.apple-login {
  background-color: #000;
}

.loginForm p {
  margin-bottom: 8px;
}

.registernow {
  font-size: 15px;
  color: #444;
  margin-top: 15px;
  margin-bottom: 0 !important;
  text-align: center;
}
.registernow span,
.registernow a {
  font-weight: 600;
  cursor: pointer;
  color: var(--secondary--color);
}
.insertimage input {
  display: none;
}

.insertimage {
  text-align: center;
}

.insertimage .image-container {
  width: 95px;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.insertimage .image-container label {
  position: absolute;
  right: 0;
  border-radius: 100%;
  background-color: #fff;
  z-index: 10;
  bottom: 0;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}
.insertimage .image-container img {
  border: 2px solid;
  object-fit: cover;
  width: 95px;
  height: 95px;
  border-radius: 100%;
}

.editprofile-container {
  padding: 25px;
  background: #eee;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.editprofile-container input {
  display: block;
  width: 100%;
  margin-top: 6px;
  padding: 8px 10px;
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 8px;
}
.editprofile-container label {
  display: block;
  font-family: "Poppins", sans-serif;
  color: var(--secondary--color);
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 14px;
  position: relative;
}

.editprofile-container label span {
  color: var(--primary--color);
}
.pageheadings-wrapper {
  padding: 75px 0;
  background-image: linear-gradient(
    to left,
    rgba(218, 26, 50, 0.36) 0%,
    rgba(218, 26, 50, 0.27) 41.67%,
    rgba(0, 67, 123, 0.35) 86.98%
  );
}
.pageheadings-wrapper > div {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}

.pageheadings-wrapper > div h1 {
  color: #333;
  font-weight: 800;
}

.page-container {
  padding-top: 45px;
}
.singleblog-container {
  padding: 15px 0;
}
.singleblog-container h3 {
  line-height: 1.5;
}

.pageheading-date {
  width: 100%;
  padding-right: 40px;
  position: absolute;
  bottom: -45px;
  display: flex;
  justify-content: space-between;
}
.blog-information p {
  color: #333;
  font-size: 15px;
  font-weight: 400;
}
.blog-share i {
  font-size: 19px;
  margin-left: 10px;
  color: var(--primary--color);
}
.page-content table p img {
  margin-bottom: 0;
}
.page-content table p {
  display: flex;
  align-items: center;
  gap: 10px;
}
.page-content {
  margin-bottom: 15px;
  overflow: auto;
}
.page-container .page-content p,
.page-container .page-content p,
.page-content p {
  line-height: 29px;
  font-size: 17px;
  color: #333;
  font-family: Malgun;
}

.page-content p {
  margin-bottom: 15px;
}

.page-content img {
  margin-bottom: 15px;
}
.page-container {
  padding: 35px 0;
}
.latestblog {
  padding-left: 30px;
  margin-left: 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.latestblog h2 {
  color: #333;
  padding-bottom: 25px;
  font-weight: 600;
}

.forgotpassword-container :is(h2, p) {
  margin-bottom: 15px;
}
.dashboard-page-title {
  color: var(--secondary--color);
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 25px;
}
.setsitem-container {
  padding: 18px 25px 12px 25px;
  height: 100%;
  position: relative;
}
.setsitem-container.item-bought {
  background-color: #f2fff7;
}

.pdficon {
  display: flex;
  align-items: center;
}

.setsitem-container .pdficon img,
.normalsetsitem .pdficon img {
  width: 50px;
}

.sets-content,
.chapter-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.sets-content h5,
.chapter-content h5 {
  font-size: 18px;
}

.setstitem-container p,
.pricepkg,
.chapter-content p {
  font-size: 16px;
  font-weight: 550;
  color: #555;
}
.sets-content h3 {
  font-size: 18px;
  /* display: none; */
  cursor: pointer;
  color: var(--secondary--color);
  font-weight: 600;
}
.sets-content h3 a {
  color: #333;
}
.setstitem-container p.ubt-buy {
  font-family: "Poppins", sans-serif;
  border-radius: 26px;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
}

.setstitem-container p span,
.pricepkg span {
  font-size: 18px;
  font-weight: 650;
  font-family: var(--number--font);
  color: var(--secondary--color);
}
.progressbar-container {
  padding: 20px 55px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  background: #00437b1a;
  -o-border-radius: 8px;
}
.sticky {
  position: sticky;
  top: 20px;
}
.sticky > div {
  margin-bottom: 15px;
}
.sticky > div:last-child {
  margin-bottom: 0;
}

.testscore > p,
.progressbar-container > p {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: var(--secondary--color);
}

.test-percentage span {
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.test-percentage p {
  font-size: 26px;
  color: #111;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 7px;
}

.dashboard-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 15px 0;
}
.dashboard-progress .testscore {
  margin: 0;
}
.dashboard-results-progress {
  padding: 35px 0;
  width: 75%;
  margin: 0 auto;
}
.contactsidebar .footerhead {
  margin-bottom: 10px;
  color: var(--secondary--color);
}
.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: url("../img/arrow-left.png");
}
.eachservicecard {
  text-align: center;
  height: 100%;
  padding: 60px 35px;
  border-radius: 20px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.eachservicecard h2 {
  color: #252525;
  font-size: 26px;
  font-weight: 700;
  margin: 20px 0;
}
.eachservicecard p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #252525;
}
.servicecard-container {
  row-gap: 20px;
}
.eachservice-img {
  margin: auto;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.servicecard-container .eachservice-wrapper .eachservicecard {
  background: #fff;
  position: relative;
  overflow: hidden;
  border: 1px solid #eee;
}

.services-section .eachservicecard::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -22px;
  background: url("../img/ubtbanner-bg.png") 0 0 no-repeat;
  width: 305px;
  height: 130px;
  opacity: 0.5;
}

.testimonial-section {
  background-image: linear-gradient(
    to bottom,
    var(--secondary--color),
    #c7303ce3
  );
}
.testimonial-container {
  position: relative;
}

.slick-prev::before,
.slick-next::before {
  color: #00d1ff;
}
.slick-arrow {
  position: absolute;
  bottom: -35px;
  top: auto;
  transform: translate(50%, 0);
  left: 50%;
  right: 0;
}
.slick-prev {
  left: 48%;
}
.slick-track .slick-slide {
  padding-left: 15px;
}

.slick-track .slick-slide .slick-content {
  background-color: #fff;
  padding: 35px;
}
.slick-track .slick-active:last-child .slickcontent {
  margin-right: 0;
}

.miniblog-container .BlogDate {
  margin: 0;
  margin-bottom: 5px;
}

.BlogDate p,
.viewers {
  font-weight: 400;
  display: inline-block;
  color: #767676;
  font-size: 14px;
}

.miniblog-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 15px;
  padding-bottom: 20px;
}
.miniblog-container > a {
  grid-column: span 1;
}
.miniblog-container > div {
  grid-column: span 2;
}
.blog-container img {
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.miniblog-container img {
  max-height: 75px;
  min-height: 75px;
}
.miniblog-container h4 {
  font-size: 17px;
  line-height: 1.35;
}

.mainblog-container > a > h3 {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 10px;
}
.db-BlogContainer .mainblog-container > a > h3 {
  font-size: 16px;
  line-height: 1.25;
}

.mainblog-container > p {
  font-size: 15px;
  line-height: 25px;
  color: #64607d;
  font-weight: 400;
}

.footer-section {
  background-image: linear-gradient(180deg, var(--secondary--color), #00447be6);
  padding-top: 135px;
  position: relative;
  overflow: hidden;
}
.footer-section > svg {
  z-index: 1;
}
.footer-section.otherpage-footer {
  padding-top: 95px;
}
.footer-section .container {
  position: relative;
  z-index: 1;
}

.footer-section .footer-korean {
  position: absolute;
  bottom: 25px;
  left: 45px;
  width: 200px;
}
.footer-section .footer-korean img {
  opacity: 0.3;
}
.custom-shape-divider-top-1679479740 {
  position: absolute;
  top: 0;
  z-index: 2;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1679479740 svg {
  position: relative;
  display: block;
  width: calc(152% + 1.3px);
  height: 35px;
}

.custom-shape-divider-top-1679479740 .shape-fill {
  fill: #ffffff;
}
.footerlogo img {
  width: 200px;
}
.custom-shape-divider-top-1673324135 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 1;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.custom-shape-divider-top-1673324135 svg {
  position: relative;
  display: block;
  width: calc(131% + 1.3px);
  height: 35px;
}

.custom-shape-divider-top-1673324135 .shape-fill {
  fill: #ffffff;
}
.footer-links {
  list-style: none;
  padding: 0;
}
.footer-section .footerhead {
  color: #fff;
  margin-bottom: 20px;
  font-size: 22px;
}
.footer-section .socialMedia {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 15px;
  margin: 0;
}
.footer-section .socialMedia i {
  color: #fff;
}
.footer-links li {
  color: #fff;
  padding-bottom: 10px;
}
.footer-links li:last-child {
  padding-bottom: 0;
}
.footer-links li a,
.footer-links li span {
  font-size: 14px;
}
.footer-links.black li a,
.footer-links.black li span,
.footer-links.black li i {
  color: #111;
}
.footer-links li a,
.footer-links li span,
.socialMedia li a i {
  color: rgba(255, 255, 255, 0.75);
  word-break: break-all;
  transition: all 0.3s ease;
}

.bottomFooter ul li a:not([href]):not([class]) {
  color: rgba(255, 255, 255, 0.849);
}
.footer-links li a:hover,
.footer-links li span:hover {
  color: var(--primary--color);
}

.footer-links li i {
  margin-right: 10px;
}

.bottomFooter {
  padding: 20px 0 15px 0;
  margin-top: 35px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bottomFooter ul {
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
}
.bottomFooter ul li:first-child {
  padding-left: 0;
  border-left: none;
}
.bottomFooter ul li:last-child {
  padding-right: 0;
}
.bottomFooter ul li {
  padding: 0 25px;
  line-height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.bottomFooter ul li a,
.bottomFooter .copyrights p,
.bottomFooter .copyrights p a {
  color: rgba(255, 255, 255, 0.849);
  font-size: 14px;
  font-weight: 500;
}

.download-content {
  margin-top: -15px;
}
.download-content p {
  font-size: 18px;
}

.downloadBtn {
  text-align: center;
}

.burger-menu {
  padding: 5px 0;
}

.burger--menu {
  font-size: 30px;
  margin-left: 15px;
  color: var(--secondary--color);
}

.topBar {
  display: flex;
  justify-content: center;
  align-content: center;
}
.menuBar {
  display: flex;
  align-items: center;
  color: var(--secondary--color);
  justify-content: flex-end;
  width: 100%;
}

.navIcon {
  display: flex;
  cursor: pointer;
}
.sideMenu {
  position: fixed;
  overflow-y: scroll;
  overscroll-behavior: contain;
  left: 0;
  top: 0;
  height: 100vh;
  width: 260px;
  z-index: 111;
  background: var(--secondary--color);
  transition: all 0.35s ease-out;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -ms-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
}
.sideMenu a {
  position: relative;
  display: flex;
  align-content: flex-start;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  color: #fff !important;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sideMenu a > div {
  position: absolute;
  right: 15px;
  padding: 10px;
  top: 6px;
}
.sideMenu > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.burger--menu {
  display: none;
}

/* Dashboard CSS */

.dashboard__search input {
  padding: 12px 50px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.formWrap {
  position: relative;
}

.formWrap i {
  position: absolute;
  left: 18px;
  top: 14px;
  color: #ada7a7;
}

.dashboard__right > a {
  display: inline-block;
  color: #fff;
  font-size: 22px;
  margin-left: 10px;
  position: relative;
  vertical-align: middle;
}

.dashboard__right > a.dashboard__settings {
  color: #fff;
}

.dashboard__right > a span {
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 20px;
  line-height: 20px;
  height: 20px;
  background: #da1a32;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.dashboard__user {
  display: inline-block;
  margin-left: 20px;
  position: relative;
}

.dashboard__user > a {
  display: flex;
  align-items: center;
}
.dashboard__user .profile-text p {
  line-height: 120%;
  padding-left: 10px;
}
.dashboard__user .profile-text p:first-child {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.dashboard__user .profile-text p:last-child {
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 15px;
}

.dashboard__user div > a {
  display: flex;
  align-items: center;
}

.dashboard__user > a img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.dashboard__header .logo img {
  width: 150px;
}

.dashboard__navbar .logo {
  margin-bottom: 15px;
}

.dashboard__navbar h3 {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 30px;
}
.dashboard__body {
  padding: 0 180px 0 20px;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(218, 26, 50, 0.63) -93.83%,
    rgba(218, 26, 50, 0.44) -12.21%,
    rgba(0, 67, 123, 0.44) 62.27%
  );
  background: #00447bf2;
}
.dashboard-nav-wrap {
  padding: 0;
}
.dashboard__body .copyright {
  /* position: absolute; */
  left: 50%;
  /* transform: translateX(-50%); */
  bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
  /* background: #fff; */
  color: #fff;
}
.dashboards-allcontentwrapper {
  padding-right: 0;
  padding-left: 20px;
}

.dashboards-allcontent {
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  padding: 45px;
}

.resultUpdate h2 {
  font-size: 30px;
  font-weight: 600;
  color: var(--secondary--color);
  text-transform: uppercase;
}

.resultUpdate p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #252525;
  margin-bottom: 15px;
}

.resultUpdate > span {
  color: rgba(0, 0, 0, 0.71);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
  font-weight: 600;
}

.resultUpdate > span i {
  font-size: 20px;
  color: rgba(0, 67, 123, 1);
  vertical-align: middle;
  margin-left: 5px;
}

.resultUpdate p span {
  font-weight: 400;
  margin-left: 8px;
}

.dashboard__navbar > div > ul:not(.socialMedia) > li {
  display: flex;
  align-items: center;
}
.dashboard__navbar li img {
  margin-right: 12px;
  height: 23px;
}
.quick-links {
  padding-top: 25px;
  margin-top: 15px;
  border-top: 1px solid #eeeeee29;
}
.social--links ul {
  padding-left: 0;
}
.dashboard__navbar > div > ul:not(.socialMedia) > li {
  align-items: center;
  display: flex;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 10px 35px 10px 15px;
}
.dashboard__navbar > div.dashboard__navbar--items > ul > li.active {
  background: #c7303ccf;
}
.dashboard__navbar > div > ul > li {
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
}
.dashboard__navbar .quick-links h3 {
  margin-bottom: 15px;
}
.dashboard__navbar > div > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dashboard__navbar .quick-links > ul > li {
  margin-bottom: 15px;
}
.dashboard__main .dashboard-page-title {
  color: #111;
  margin: 0;
  margin-bottom: 8px;
  font-size: 20px;
}

.dashboard__main .dashboard-page-title span {
  color: var(--secondary--color);
  word-wrap: anywhere;
}

.dashboard__main .setstitem-container > p {
  font-size: 16px;
  font-weight: 500;
  color: #252525;
}

.quick-links li i {
  margin-right: 7px;
  font-size: 14px;
}

.social--links ul li {
  display: inline-block;
  margin-right: 8px;
}

.social--links ul li i {
  font-size: 16px;
}

.btn-green {
  background-color: green;
  box-shadow: inset 0 0 0 0 var(--secondary--color);
}
.btn-green:hover {
  background-color: var(--secondary--color);
}
.btn-secondary {
  background: var(--primary--color);
  box-shadow: inset 0 0 0 0 var(--secondary--color);
}

.btn-secondary:hover {
  background: var(--secondary--color);
  color: #fff;
  box-shadow: inset 0 -100px 0 0 var(--secondary--color);
}

.btn-outline {
  background: #fff;
  border: 1px solid rgba(10, 11, 12, 0.28);
  color: #252525;
  box-shadow: inset 0 0 0 0 var(--secondary--color);
}

.btn-outline:hover {
  background: var(--secondary--color);
  color: #fff;
  box-shadow: inset 0 -100px 0 0 var(--secondary--color);
}

.btn-small {
  font-size: 12px;
  padding: 10px 25px;
}

.dashboard__navbar .btn.btn-secondary {
  width: 100%;
}

.dashboard-section {
  padding-top: 35px;
}
/* .dashboard-section > h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.dashboard-titles {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-section > h3 > a {
  color: var(--secondary--color);
  font-size: 15px;
  vertical-align: middle;
  margin-left: 10px;
}

.quick-links > ul > li > a {
  font-size: 15px;
}

/* .setsitem-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
} */
.setsitem-container.item-bought {
  position: relative;
}

/* .setsitem-container.item-bought::after {
  content: "\F26B";
  position: absolute;
  right: 30px;
  top: 30px;
  width: 50px;
  height: 50px;
  font-family: bootstrap-icons;
  font-weight: 900;
  font-size: 30px;
  color: #2dc071;
} */

.buyset--container {
  background: #76d8a3;
  margin-bottom: 25px;
  color: #fff;
  padding: 15px 25px;
  letter-spacing: 0.2px;
  font-weight: 500;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  align-items: center;
}

.buyset--container > a {
  margin-left: auto;
  color: #fff;
  font-size: 25px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.dashboard-contents {
  background-color: #f3f3f3;
  border-radius: 7px;
  padding: 25px;
  position: relative;
}

.dashboard-buytest {
  position: relative;
}
.payment-contents {
  padding: 25px;
}
.payment-contents .paymentsuccesful {
  font-weight: 700;
  margin-bottom: 15px;
  color: #18c07a;
}
.dashboard-contents .dashboard-lighttext {
  font-weight: 400;
  color: #666;
  line-height: 24px;
  font-size: 15px;
}
.payment-contents .payment-info {
  padding: 45px 0;
}
.payment-contents .payment-info ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.payment-contents .payment-info ul li {
  font-size: 16px;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 500;
  color: #444;
}
.payment-contents .payment-info ul li:first-child {
  padding-top: 0;
}
.payment-contents .payment-info ul li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.payment-contents .payment-info ul li strong {
  font-weight: 600;
  margin-right: 5px;
  color: #000;
}
.payment-contents .payment-items {
  padding-bottom: 45px;
}
.payment-contents .payment-items table {
  border-collapse: collapse;
  border-radius: 5px;
  border: 1px solid rgba(0, 67, 123, 0.3);
  text-align: center;
}
.payment-contents .payment-items table thead {
  background-color: #00447b44;
  color: var(--secondary--color);
}
.payment-contents .payment-items table thead tr th:last-child {
  border-right: 0;
}
.payment-contents .payment-items table thead tr th {
  padding: 10px 35px;
  border-right: 1px solid rgba(0, 67, 123, 0.3);
}
.payment-contents .payment-items table tbody tr td {
  padding: 10px 25px;
}
.payment-contents .payment-items table tbody tr {
  border-bottom: 1px solid rgba(0, 67, 123, 0.3);
}
.payment-contents .payment-items table tbody tr:last-child {
  border-bottom: 0;
}
.payment-contents .payment-items table tbody tr td {
  border-right: 1px solid rgba(0, 67, 123, 0.3);
}
.payment-contents .payment-items table tbody tr td:last-child {
  border-right: 0;
}
.dashboard-contents:not(:first-child) {
  margin-top: 15px;
}
.dashboard-contentshead img {
  width: 55px;
  height: 55px;
}

.rightsidebar > div:first-child {
  margin-top: 0;
}
.dashboard-contentshead {
  position: relative;
  display: flex;
  padding: 20px 0;
  align-items: center;
}
.eachdashboard-detail {
  padding: 20px 0;
  border-top: 1px solid #ddd;
}

.packageTime {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--secondary--color);
  padding: 5px 15px;
  border-radius: 80px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 12px;
  vertical-align: middle;
}

.packageTime i {
  margin-right: 5px;
}
.packageName {
  margin-left: 15px;
}
.eachdashboard-detail h3,
.packageName h3 {
  font-size: 18px;
  color: var(--secondary--color);
  font-weight: 600;
}

.eachdashboard-detail p,
.packageName p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  line-height: 25px;
  color: #252525;
  margin-top: 7px;
}
.choosepayment {
  padding: 30px 0;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.choosedigital-payment > .btn:last-child {
  margin-left: 10px;
}

.dashboard-note {
  font-size: 14px;
  line-height: 22px;
  margin: 25px 0;
  font-weight: 500;
}
.dashboard-note strong {
  font-size: 15px;
  font-weight: 600;
}

.grammar__section {
  padding: 25px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

.grammar-search input {
  padding: 12px 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.grammar-search form {
  position: relative;
}

.grammar-search form button {
  position: absolute;
  right: 0;
  bottom: 0;
  /* height: 100%; */
  border-radius: 0;
  border-radius: 0 8px 8px 0;
  padding: 10px 20px;
}

.grammar-search form button i {
  font-size: 20px;
}

.grammar--topics {
  margin-top: 25px;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.grammar--topics > div {
  margin-right: 10px;
  margin-top: 10px;
}

.grammar--details {
  position: relative;
  margin-top: 25px;
  background: #fff;
}

.grammar--details.singleselected {
  margin-top: 0;
}
.grammar--details > div:not(.btn) {
  border-bottom: 1px solid #eee;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.grammar--details > div:last-child {
  border-bottom: 0;
  margin: 0;
}

.grammar--details > div h3 {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  color: var(--secondary--color);
  margin-bottom: 10px;
}

.grammar--details > div h3 a {
  color: var(--secondary--color);
}

.grammar--details > div {
  /* margin-bottom: 20px; */
  padding: 25px;
}

.grammar--details > div p {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.75);
}

.red-bg-sidebar {
  background: #fddeee;
}

.red-bg-sidebar a {
  color: #fff;
}
.grammar-container .red-bg-sidebar span,
.grammar-container .red-bg-sidebar p,
.grammar-container .red-bg-sidebar h3,
.grammar-container .red-bg-sidebar h6 {
  color: #333;
  position: relative;
  z-index: 5;
}
.grammar-container .red-bg-sidebar {
  padding: 25px;
  color: #fff;
  border-radius: 8px;
}
.grammar-container .red-bg-sidebar h3 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}

.grammar-container .red-bg-sidebar p h3 {
  margin-top: 15px;
}
.grammar-container .red-bg-sidebar .word-headertext span {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.grammar-container .red-bg-sidebar span {
  margin-bottom: 8px;
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-align: justify;
  line-height: 1.5;
  font-weight: 400;
}

.grammar-container .red-bg-sidebar p {
  font-size: 14px;
  text-align: justify;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.header-wrap {
  margin-bottom: 25px;
}

.grammar--share > a,
.grammar--share i {
  color: var(--primary--color);
  font-size: 22px;
  margin-left: 8px;
  cursor: pointer;
}

.grammarDetails:not(:last-child) {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #25252543;
}

.grammarDetails h3 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--secondary--color);
}

.grammarDetails h4 {
  width: 75px;
  margin-top: 5px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  color: rgba(24, 2, 2, 1);
  margin-right: 8px;
}

.grammarDetails p {
  font-size: 16px;
  color: #252525;
  text-align: justify;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}

.grammarDetails > div > div {
  margin-bottom: 15px;
}

.grammarDetails ul {
  margin: 0;
}
.grammar-search .form-text {
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 8px;
  display: block;
  font-weight: 500;
}

.recentSearch > a {
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 8px 25px;
}

.recent__search h3 {
  color: var(--secondary--color);
  margin-bottom: 25px;
}

.dictonarySidebar {
  position: sticky;
  top: 25px;
  padding: 15px 25px;
  border-radius: 5px;
  background-color: #eae8fe50;
}

.dictonarySidebar h2.footerhead {
  font-size: 22px;
  margin-bottom: 25px;
}

.dictonarySidebar > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dictonarySidebar > ul > li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.dictonarySidebar > ul > li::after {
  content: "\F285";
  font-family: bootstrap-icons;
  float: right;
}

.dictonarySidebar > ul > li > a {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #252525;
  font-weight: 600;
}

.dictonarySidebar > ul > li > a:hover {
  color: var(--secondary--color);
}

.dashboard-page-title > a {
  color: rgba(0, 67, 123, 1);
  opacity: 0.8;
  font-size: 35px;
  margin-left: 15px;
  vertical-align: middle;
}

.timer {
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 25px;
  background-color: aliceblue;
}
.timer span {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  display: flex;
  color: var(--secondary--color);
}

.timer span i {
  margin-right: 8px;
}
.rightsidebar > div > .test-summary {
  background-color: #fff2d0;
  border: 0 !important;
}
.test-summary h3 {
  color: #333;
}
.questions {
  background: #f7f7f7cc;
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 25px;
}

.questions > h4 {
  font-family: Malgun;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #00000026;
  color: rgba(0, 0, 0, 0.92);
}

.questions span {
  font-family: Malgun;
  font-size: 19px;
  font-weight: bold;
  color: var(--secondary--color);
}

.questions span p {
  line-height: 1.5;
}
.questionGroups > span {
  margin-top: -3px;
}

.questions > div h5 {
  line-height: 30px;
  flex: 2;
  font-size: 18px;
  margin-left: 15px;
}
.questions .image-container {
  text-align: center;
  padding: 10px;
  background: #eae8fe;
  border: 1px solid #eee;
  border-radius: 8px;
}
.image-container img {
  width: 150px;
}

.image-container.audioImg img,
.resultItem .audioPlayClass {
  width: 75px;
  cursor: pointer;
}

.resultItem .audioPlayClass {
  width: auto;
  cursor: pointer;
}

.resultItem p.active .audioPlayClass {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}

.image-container p {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.profile-text {
  margin-top: 25px;
}
.profile-text h4 {
  color: var(--secondary--color);
}

.profile-text p {
  color: #111;
}
.mcqAnswers {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  position: relative;
  z-index: 111;
}
.questionGroups p {
  line-height: 1.5;
}
.questionGroups h4 {
  margin-bottom: 20px;
}

.mcqAnswers .form-check label {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 5px;
}

.cta__btns > button {
  margin-left: 15px;
}

.cta__btns > button i {
  color: #fff;
}
.cta__btns > button.btn-outline i {
  color: #252525;
  float: left;
}

.dashboard__user .dashboard-settings {
  position: absolute;
  left: 0;
  top: 130%;
  width: 220px;
  z-index: 111;
  padding: 0;
}

.dashboard__user .dashboard-settings li a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 13px 45px 13px 15px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.dashboard__user .dashboard-settings li:last-child a {
  border: none;
}

.navbar .hovermenu-container .hovermenu {
  background: #fff;
  width: 220px;
}

.navbar .hovermenu-container:hover ul.hovermenu li a {
  color: #252525;
  border-bottom: 1px solid #eee;
}

.navbar .hovermenu-container:hover ul.hovermenu li a:hover {
  color: var(--secondary--color);
}

.notverified-container {
  position: relative;
}

.notverified-container p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.notverified-container input {
  padding: 8px 10px;
  margin-right: 15px;
  width: 50px;
  text-align: center;
  border: none;
  border-bottom: 2px solid var(--secondary--color);
}

.notverified-container input::-webkit-outer-spin-button,
.notverified-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.notverified-container input[type="number"] {
  -moz-appearance: textfield;
}

.notverified-container button {
  margin-left: 15px;
}

.currentprofile-view {
  padding: 25px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.dashboard__right .profile-text {
  margin: 0;
}

.profile-text .btn {
  margin-top: 8px;
}

.dashboard__navbar {
  height: 100vh;
  position: sticky;
  top: 0;
}

.eachsummary > span {
  font-family: "Poppins", sans-serif;
  display: block;
  position: relative;
  color: rgba(0, 0, 0, 1);
  padding-left: 25px;
  margin-top: 15px;
}

.eachsummary > span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  background-color: #0000004d;
  width: 15px;
  height: 15px;
  border-radius: 50px;
}
.eachsummary > span.unsolvedQuestions::before {
  background-color: #00000012;
}

.spinnerLoader {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: var(--secondary--color);
}
.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 32px;
  height: 32px;
  margin-right: 5px;
}
.spinner .path {
  stroke: #93bfec;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.eachsummary.resultSummary > span.correctQuestions::before {
  background: var(--secondary--color);
}

.eachsummary.resultSummary > span.wrongQuestions::before {
  background: var(--primary--color);
}

.questions-items li a.solvedAnswer {
  background: rgba(41, 207, 107, 1);
  color: #fff;
}

.questions-items li a.wrongAnswer {
  background: rgba(248, 209, 214, 1);
  color: rgba(218, 26, 50, 1);
}

/* .newTest::before {
  content: "";
  position: absolute;
  left: -2px;
  top: -7px;
  background-image: url("../img/newTest.png");
  background-repeat: no-repeat;
  width: 60px;
  height: 70px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
} */

.eachdashboard-detail p i {
  margin-right: 8px;
}

.choosedigital-payment p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.6;
  color: var(--secondary--color);
}

.cta__btns > a {
  margin-left: 15px;
}

.btn-active {
  background: var(--secondary--color);
  color: #fff;
}

.resultItem .bi.bi-check2 {
  color: rgba(41, 207, 107, 1);
  opacity: 1;
}

.resultItem .bi.bi-x-lg {
  color: rgba(218, 26, 50, 1);
}

.resultItem i {
  font-size: 25px;
  margin-left: 15px;
  line-height: 100%;
  position: absolute;
  top: 2px;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 1;
}

.questionOption {
  width: 80%;
  height: 100%;
  position: relative;
  text-align: center;
  background-color: #fff;
  padding: 15px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.questionOption.selected {
  border-color: var(--secondary--color);
}
.questionOption p {
  display: inline-block;
  padding-left: 50px;
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 30px;
  font-family: Malgun;
}
.questionOption p.played {
  position: relative;
  pointer-events: none;
  cursor: not-allowed;
}
/* .questionOption p.played::after {
  content: "\F659";
  font-family: bootstrap-icons;
  font-weight: 900;
  color: red;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  font-size: 55px;
} */

.questionOption p.played img {
  transform: scale(0.9);
}

.questionOption span {
  position: absolute;
  left: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
  top: 50%;
  background-color: #fff;
  transform: translateY(-50%);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  transition: all 0.3s ease;
  z-index: 111;
  font-size: 14px;
  line-height: 26px;
  cursor: pointer;
  color: #111;
}

.questionOption span:hover,
.questionOption.correct span,
.questionOption.selected span,
.questionOption.resultItem.correct span {
  background-color: var(--secondary--color);
  color: #fff;
}
.questionOption.resultItem span {
  pointer-events: none;
  position: absolute;
  left: 16px;
}

.questionOption img {
  width: 200px;
}
.questionOption .optionimg img {
  width: auto;
}
.questions h4 span p span,
.questions > div h5 span p span {
  font-size: 16px !important;
  font-family: Malgun !important;
  font-weight: 400;
}

.questions > div h5 span {
  font-size: 18px;
  color: #252525;
}

.questions > div h5 {
  margin: 0;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #a2a2a2;
  border-radius: 8px;
  color: #252525;
}

.questions > div h5 img {
  margin: auto;
}

.modal-body {
  text-align: center;
}

.questionOption p .audioPlayClass:focus,
.questionOption p .audioPlayClass:active,
.questionOption p .audioPlayClass:visited {
  transform: scale(0.9);
  background-color: #eee;
}

.questionOption p.played .audioPlayClass:focus,
.questionOption p.played .audioPlayClass:active,
.questionOption p.played .audioPlayClass:visited {
  transform: scale(1);
}
.dashboard__header {
  padding: 15px 0;
}

.quesTitle {
  flex: 1;
  margin-left: 15px;
}

.image-container img.audio {
  width: auto;
}

.site__header.absolute {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
}
.site__header.sticky-top {
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: #fff;
}
.site__header.relative {
  width: 100%;
  z-index: 11;
  position: relative;
  box-shadow: 0 5px 7px #00000012;
}

.site__header.sticky-top .logo img {
  padding: 15px 0;
  width: 150px;
}

.bannerServices .eachservicecard {
  position: relative;
  overflow: hidden;
  text-align: center;
  box-shadow: 0px 0px 54px 0px
    rgba(9.000000000000032, 46.000000000000334, 195, 0.06);
  background-color: #fff;
  padding: 55px 20px 55px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.bannerServices .eachservicecard:hover {
  transform: scale(1.08);
}
.flip-card {
  display: block;
  background-color: transparent;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
}
.flip-card-front {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.flip-card-back {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 100%;
  transition: all 0.5s ease-out;
  padding: 0 10px;
}
.bannerServices .eachservicecard .flip-card-back h3,
.bannerServices .eachservicecard .flip-card-back p {
  color: #fff;
}
.flip-card-inner:hover .flip-card-back {
  top: 0;
  opacity: 1;
  height: 100%;
  width: 100%;
  pointer-events: all;
  background-color: var(--secondary--color);
}

.bannerServices .eachservicecard h3 {
  color: var(--secondary--color);
  font-size: 20px;
  font-weight: bold;
  font-family: "Urbanist", sans-serif;
  padding: 26px 0px 10px 0px;
  margin: 0;
}

.bannerServices .eachservice-img {
  margin: 0 auto;
  padding: 0;
  background: transparent;
}

.bannerServices {
  margin-top: -120px;
  position: relative;
}

.bannerServices .eachservice-img img {
  width: auto;
}

.about-banner {
  position: relative;
}

.about-banner .img-2 {
  position: absolute;
  left: -40px;
  bottom: -40px;
}

.about-banner img {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.blog-section {
  background: #f8f8f8;
}

.modal-body .btn {
  margin-left: 8px;
}

.modal-body p {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 8px;
}

.questionGroups > div {
  flex: 1;
  margin-left: 19px;
}

.burger__menu {
  display: none;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.offCancvas__menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.offcanvas {
  background-image: linear-gradient(180deg, var(--secondary--color), #00447be6);
}
.offCancvas__menu > li:last-child {
  border-bottom: 0;
}

.offCancvas__menu > li {
  cursor: pointer;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.offCancvas__menu > li img {
  width: 25px;
  margin-right: 15px;
}

.offcanvas {
  width: 250px !important;
}
.offcanvas-title img {
  width: 150px;
  margin: auto;
}
.offcanvas-header {
  background: #00000024;

  text-align: center;
  display: block;
}
.offcanvas-title {
  display: block;
}

.download__app {
  position: relative;
  margin-top: 200px;
}

.downloadApp {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 500px;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--secondary--color);
  background-image: url("../img/download-pattern2.png");
  background-size: 180px;
  background-attachment: scroll;
  -webkit-transition: all 100000ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 100000ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.downloadWrapper {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 500px;
  padding-right: 290px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.downloadIcon {
  position: relative;
  z-index: 3;
}

.downloadApp .section-head span,
.downloadApp .section-head h2,
.downloadApp p {
  color: #fff;
}

.downloadApp .section-head,
.downloadApp .download-content {
  position: relative;
  z-index: 5;
}

.downloadBtn {
  text-align: center;
  display: inline-block;
}

.downloadBtn span {
  color: #fff;
}

.downloadGradient {
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: 500px;
  background-color: transparent;
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(6, 7, 32, 0)), to(#060720)); */
  background-image: linear-gradient(
    180deg,
    rgba(6, 7, 32, 0),
    var(--secondary--color)
  );
}

.image-container.audioImg + .mcqAnswers p img {
  width: 150px;
  height: 100%;
}

.dashboard__navbar.heightfull .burger--menu {
  display: none;
}
.startTest {
  position: relative;
  padding: 115px 35px;
  border-radius: 12px;
  background-color: #00437b1a;
}
.startTest p {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  margin-left: 8px;
  font-size: 17px;
  font-weight: 600;
}

.startTest h5 {
  font-size: 24px;
  color: var(--secondary--color);
}

.timetostart {
  margin: 10px 0;
  font-weight: bold;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}
.timetostart img {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}
.timetostart span {
  font-size: 20px;
  font-weight: 600;
}
.image-container p.note {
  font-weight: 500;
}

.image-container.audioImg + .mcqAnswers p.active img {
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.questionOption.resultItem.correct span {
  background-color: var(--secondary--color);
  border-color: var(--secondary--color);
  color: #fff;
}
.questionOption.resultItem.notcorrect span {
  border-color: var(--primary--color);
  background-color: var(--primary--color);
  color: #fff;
}

.questions {
  position: relative;
}
.grammarDay {
  position: relative;
}
.dashboard-test {
  position: relative;
}
.startTest::after,
.dashboard-test::after,
.dashboard-buytest::after,
.grammarDay::after,
.questions::after,
.startDiscussion::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -15px;
  background: url("../img/ubtbanner-bg.png") 0 0 no-repeat;
  width: 305px;
  height: 130px;
  opacity: 0.5;
  z-index: 3;
}

.image-container.audioImg + .mcqAnswers p img.audioPlayClass {
  width: auto;
}

.esewaSuccess span {
  color: #169514;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

/* .totalscore img {
  width: 43px;
  position: absolute;
  left: 20px;
  top: 10px;
} */

.postBlog {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 15px 0;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}

.postBlog div.blog-date i {
  margin-right: 8px;
}
.page-wrap h3 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
}

.page-wrap span {
  font-style: italic;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.page-wrap {
  position: relative;
}

.pageBanner {
  padding: 90px 0;
  background: var(--secondary--color);
  position: relative;
  overflow: hidden;
}

.buyPackages {
  position: relative;
  overflow: hidden;
}

.pageBanner > svg,
.eachservicecard svg,
.footer-section > svg,
.buyPackages svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 288px;
  height: 270px;
  animation: 7s infinite float;
  -webkit-animation: 7s infinite float;
  opacity: 0.5;
}

.pageBanner > svg,
.footer-section > svg {
  position: absolute;
  left: -120px;
  top: -210px;
  width: 1200px;
  height: 740px;
}

.buyPackages svg {
  left: -40px;
  top: -80px;
  width: 500px;
  height: 380px;
  opacity: 0.2;
}

.eachservicecard svg {
  width: 450px;
  height: 560px;
  opacity: 0.1;
}

/* .pageBanner::before {
  width: 288px;
  height: 270px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("../img/lkwb.svg") 0 0 no-repeat;
  animation: 7s infinite float2;
  -webkit-animation: 7s infinite float2;
} */

.waves-wrapper .waves .wave2 {
  animation: 7s infinite float2;
  left: 0;
  animation-delay: 2s;
}
@keyframes float {
  0%,
  100% {
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
  }
  50% {
    transform: scale(1.65);
    -webkit-transform: scale(1.65);
    -moz-transform: scale(1.65);
    -ms-transform: scale(1.65);
    -o-transform: scale(1.65);
  }
}

.image-container img.audioPlayClass {
  width: 80px;
}

.page-content p {
  font-size: 18px;
  text-align: justify;
  font-weight: 400;
  line-height: 1.75;
}

.page-content ul,
.page-content ol {
  padding-left: 0px;
  padding-bottom: 20px;
}

.page-content ul li,
.page-content ol li {
  font-size: 18px;
  text-align: justify;
  font-weight: 600;
  line-height: 1.75;
}
.blog-content img {
  width: 100%;
}
.page-content p strong img {
  margin-right: 35px;
}

.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.page-content h5,
.page-content h6 {
  margin-top: 0;
  font-weight: 400;
  margin-bottom: 20px;
  color: var(--secondary--color);
  line-height: 1.3;
}
.page-content blockquote {
  display: block;
  width: 100%;
  line-height: 1.68;
  font-size: 1.4rem;
  position: relative;
  border-bottom: none;
  margin-bottom: 27px;
  background: #ffffff0a;
  font-weight: 600;
}
.page-content blockquote,
.page-content blockquote p {
  text-align: center;
  color: var(--secondary--color);
  font-weight: bold;
  font-size: 1.4rem;
  margin: 0;
}
.page-content blockquote::before,
.page-content blockquote::after {
  content: "\F6B0";
  font-family: bootstrap-icons;
  font-weight: 900;
  font-size: 50px;
  display: block;
}
.page-content blockquote::after {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.page-content table {
  width: 100% !important;
  border-collapse: collapse;
}

.page-content table tr td {
  font-size: 16px;
  padding: 8px 26px;
  border-top: 1px solid #ecf0f1;
  border-left: 1px solid #ecf0f1;
  border-right: 1px solid #ecf0f1;
}
.page-content table tr:nth-child(even) {
  background: #f1f1f1;
  font-size: 16px;
}

.page-content table tr td p {
  margin: 0;
}

.page-content table tr td p a,
.page-content table p a {
  color: var(--secondary--color);
}

.grammar--details {
  margin-top: 25px;
  /* padding-right: 15px; */
}

.home__dictionary {
  padding: 50px 0;
}

.loading__mode {
  padding: 25px 0 !important;
  text-align: center;
}

.closeTest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.closeTest h3 {
  font-size: 25px;
}

.dashboard-results__wrapper .close {
  text-align: right;
  font-size: 25px;
  position: absolute;
  right: -10px;
  top: -40px;
  cursor: pointer;
}
span.close,
.dashboard-results__wrapper .close,
.dashboards-allcontentwrapper .close {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  color: #fff;
  background-color: var(--secondary--color);
  display: flex;
  justify-content: center;
  align-items: center;
}
span.close {
  display: block;
  text-align: right;
  font-size: 20px;
  cursor: pointer;
}

.closeTest > span i,
.dashboard-results__wrapper .close i,
.dashboards-allcontentwrapper .close i {
  font-size: 15px;
}
.menuProfile {
  display: none;
}

.menuProfile img {
  padding: 0;
}

.favContent .grammar--details {
  height: auto;
}

.favContent .grammar__section {
  margin-bottom: 25px;
}

.questions-items li.results-questions {
  cursor: pointer;
}

.dashboard--card {
  padding: 25px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: #eae8fe;
  position: relative;
}

.dashboard--card::after {
  content: " ";
  width: 45px;
}

.dashboard--card .totalscore {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.score p {
  font-weight: bold;
  color: var(--secondary--color);
}
.mainblog-container.single > a {
  height: 250px;
  display: block;
  position: relative;
  width: 100%;
}
.mainblog-container.twocolumn > a {
  display: block;
  width: 95%;
  height: 250px;
  position: relative;
}
.mainblog-container.twocolumn {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.mainblog-container.single .mainblog-content{
  margin-top: 10px;
}
.mainblog-container.single h3{
  margin: 5px 0 ;
}
.mainblog-container.single .anchor-text{
  margin-top: 5px;
}
.mainblog-container.single .BlogDate{
  margin: 5px 0;
}
.mainblog-container > a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainblog-container h3 {
  font-size: 20px;
  margin-bottom: 12px;
}
.mainblog-container h3 a {
  color: #252525;
}

.modalContact li {
  color: #111;
}

.modalContact li span,
.modalContact li a {
  color: #111;
  font-family: "Poppins", sans-serif;
}

.aboutModal {
  text-align: left;
}

.aboutModal p {
  font-size: 17px;
}

.aboutModal h5 {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.ad-container {
  text-align: center;
}

.ad-container > a {
  display: block;
  margin: 25px 0;
  padding: 10px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.dashboard__navbar > div > ul.socialMedia > li img {
  width: auto;
}

.esewaFailure h4 {
  color: var(--primary--color);
}

.esewaFailure span {
  display: block;
  margin: 8px 0;
}

.error404 {
  padding: 50px 0;
  text-align: center;
}

.error404 h1 {
  font-family: "Poppins", sans-serif;
  font-size: 230px;
  font-weight: bold;
  color: var(--secondary--color);
}

.error404 p {
  font-size: 25px;
  font-weight: bold;
  color: #252525;
}

.error404 i {
  margin-right: 15px;
}

.error404 .btn {
  margin-top: 25px;
}

.favContent .dashboard-page-title {
  font-size: 25px;
}

.favContent button.nav-link {
  font-size: 20px;
  font-weight: bold;
  color: var(--secondary--color);
}
.totalscore img {
  width: 50px;
}

/* .footer-section::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -15px;
  background: url("../img/ubtbanner-bg.png") 0 0 no-repeat;
  width: 305px;
  height: 130px;
  opacity: 0.5;
} */

.services-wrap .img {
  background: #fff !important;
}

.dictonarySidebar > ul > li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.dashboard__navbar.heightfull {
  height: calc(100vh - 131px);
}

.hovermenu-container {
  position: relative;
}

.hovermenu-container ul.hovermenu {
  position: absolute;
  top: 150%;
  transition: all 0.3s ease;
  left: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  pointer-events: none;
  list-style: none;
  z-index: 1111;
}

.hovermenu-container:hover ul.hovermenu {
  opacity: 1;
  top: 100%;
  visibility: visible;
  pointer-events: all;
}
.hovermenu-container:hover ul.hovermenu li:last-child > a {
  border-bottom: 0;
}
.hovermenu-container ul.hovermenu li > a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 13px 45px 13px 15px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}
.hovermenu-container ul.hovermenu li > a:hover {
  color: var(--primary--color);
}

.dashboard-settings {
  background-color: #fff;
  list-style: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
}
.rightsidebar > div > div:not(.accordion) {
  padding: 15px;
  margin-bottom: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.rightsidebar .fixedheight {
  height: 650px;
  overflow: auto;
}
.questions-container h4 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #444;
}
.questions-container h4 span {
  margin-left: 15px;
  font-size: 20px;
  font-weight: 600;
  color: var(--secondary--color);
}
.questions-items {
  display: grid;
  list-style: none;
  padding: 0;
  column-gap: 15px;
  row-gap: 15px;
  margin: 0;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.questions-items li.results-questions,
.questions-items li a {
  cursor: pointer;
  height: 45px;
  width: 45px;
  display: inline-block;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  background-color: #00000012;
  border-radius: 100%;
  transition: all 0.3s ease;
  text-align: center;
  line-height: 45px;
  font-weight: bold;
  color: var(--secondary--color);
}

.questions-items li a:hover,
.questions-items li a.active,
.questions-items li a.attempted {
  background-color: #0000004d;
  color: #000;
}

.questions-items li.results-questions.notcorrect a {
  color: #fff;
  background-color: var(--primary--color);
}

.questions-items li.results-questions.correct a {
  background-color: var(--secondary--color);
  color: #fff;
}
.questions-items li.results-questions.not_attempted,
.questions-items li.results-questions.not_attempted a {
  background-color: #f9f9f9;
}
.rightsidebar {
  position: relative;
}

.services-lists {
  padding: 55px 0;
}

.services-lists h4 {
  color: #333;
  margin-bottom: 25px;
}
.services-wrap {
  position: relative;
  display: flex;
  gap: 25px;
  align-items: flex-start;
}
.services-wrap .img {
  min-width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(238, 238, 238, 0.267);
  border-radius: 100%;
}
.services-wrap .img img {
  width: 55px;
}

.services-section svg {
  display: none;
}

.bigLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(60vh - 150px);
}
.copyRight p {
  color: #fff;
  font-size: 14px;
}

.copyRight p a {
  color: #fff;
}

.blog-share {
  display: flex;
  align-items: center;
}

.blog-share > i {
  margin-left: 15px;
  cursor: pointer;
}

.blog-share > button {
  margin-left: 8px;
}
.blog-share > button:first-child {
  margin-left: 0;
}
.commentSection {
  padding: 25px;
  border: 1px solid #eee;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.commentSection h3 {
  margin-bottom: 15px;
  font-size: 20px;
}

.userImage img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.comment__section h3 {
  margin-bottom: 15px;
}

.comment__section > div {
  position: relative;
  margin-bottom: 25px;
  padding: 25px;
  border: 1px solid #ccc;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.person-image > div {
  display: none;
}

.comment__section > div > div > div > h3,
.single__comment h3 {
  margin-bottom: 5px;
  color: var(--secondary--color);
  font-size: 17px;
  position: relative;
  line-height: 1.3;
}

.comment__section > div > div > div > h3 > a {
  color: var(--secondary--color);
}

.comment__section > div > div > div > h3 i {
  cursor: pointer;
  margin-left: 5px;
  color: var(--secondary--color);
  font-size: 14px;
  padding-left: 5px;
}
.editDelete {
  margin-left: auto;
}

.editDelete i {
  margin-left: 10px;
  color: var(--secondary--color);
  cursor: pointer;
}

.single__comment h3 {
  font-size: 22px;
  line-height: 1.3;
}

.single__comment .userProfile {
  padding: 0;
  margin: 0;
  border: none;
}

.addComment > a {
  font-size: 14px;
  font-weight: bold;
  color: #111;
}

.dashboards-allcontent > button:not(.btn) {
  background: transparent;
  border: none;
  color: #111;
  margin-bottom: 25px;
  font-weight: bold;
}

.userComments {
  padding: 10px;
  background: #f1f2f2;
  border-top: 1px solid #dee0e1;
  margin-top: 15px;
}
.single-discussion .comment-details {
  border: none;
  background-color: #ffffff8a;
}
.comment-details {
  border: 1px solid #dee0e1;
  /* border-top: none; */
  padding: 20px 15px;
  background: #f1f2f2;
}

.comment-details .commentDetails {
  flex: 1;
  margin-left: 15px;
}

.comment-details .commentDetails .comment-details {
  border: none;
  border-top: 1px solid #dee0e1;
}

.commentDetails h5 {
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 5px;
  color: var(--secondary--color);
}
.commentDetails h5 span {
  font-size: 13px;
  display: block;
  color: #666;
  font-weight: 400;
}

.commentDetails p {
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
}

.commentDetails .commentDetails-options {
  display: flex;
  gap: 15px;
  align-items: center;
}
.commentDetails .commentDetails-options div {
  font-size: 24px;
}
.commentDetails .commentDetails-options div i {
  cursor: pointer;
}
.commentDetails .commentDetails-options div .bi.bi-check2 {
  color: green;
  font-size: 28px;
}
.commentDetails .commentDetails-options div .bi.bi-x-lg {
  color: red;
}

.commentDetails input {
  background: rgb(226, 226, 226);
  padding: 8px;
  border: none;
  border-bottom: 1px solid #333;
  width: 100%;
}

.userComment {
  width: 100%;
  flex: 1;
  margin: 0 15px;
}

.userComment input {
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.commentDetails > a,
.comment__section .userProfile + button,
.commentDetails > button {
  font-size: 14px;
  font-weight: bold;
  color: #111;
  margin-top: 8px;
  margin-bottom: 8px;
  display: block;
}

.comment__section .userProfile + button,
.commentDetails > button {
  border: none;
  background: transparent;
}

.userProfile span {
  padding-left: 5px;
}

.dashboardUser__mobile {
  position: relative;
}

.dashboardUser__mobile .dashboard-settings {
  position: absolute;
  left: -163px;
  top: 130%;
}
.dashboardUser__mobile .dashboard-settings li a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 13px 45px 13px 15px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.profile-text span {
  font-size: 14px;
  font-weight: bold;
}

.grammar--share > a {
  vertical-align: middle;
}
.grammar--share > button {
  margin-left: 6px;
  vertical-align: middle;
  display: inline-block;
}

.userComments .comment-details {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.userComments .comment-details:last-child {
  border: none;
}

.grammarDay h6 {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.image-container.audioImg.activeaudio img {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.overflow {
  height: 163px;
  overflow: hidden;
}

.readMore {
  text-align: left;
  margin-top: 10px;
}

.questions-items li.results-questions.notcorrect,
.questions-items li.results-questions.notcorrect a {
  color: #fff;
  background-color: var(--primary--color);
}

.questions-items li.results-questions.correct,
.questions-items li.results-questions.correct a {
  color: #fff;
  background-color: var(--secondary--color);
}
.questions-items li.results-questions.not_attempted,
.questions-items li.results-questions.not_attempted a {
  background-color: #eee;
}

.discussionFields input {
  width: 100%;
  border: none;
}
.discussionFields textarea,
.discussionFields input {
  outline: none;
  width: 100%;
  border: 1px solid #cbcccc;
  padding: 10px 20px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.discussionFields textarea {
  height: 75px;
}

/* .postDiscussion {
  border: 1px solid #cbcccc;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
} */

.userProfile {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #11111115;
  padding-left: 65px;
}

.userProfile strong {
  line-height: 100%;
  margin: 0 5px;
}

.userProfile p {
  font-size: 14px;
  font-weight: 500;
}

.socialmedia-login > * {
  flex: 1;
}

.setstitem-container p del,
.pricepkg del {
  font-size: 14px;
  margin-left: 5px;
  background-color: rgba(240, 248, 255, 0.699);
  padding: 3px 7px;
  border-radius: 5px;
  color: #00437b8f;
  font-family: var(--number--font);
}

.boughtSet {
  margin-bottom: 35px;
}

.boughtSet .sets-content h3 {
  font-size: 18px;
}
/* .normalsetsitem {
}
.normalsetsitem:last-child {
  padding-bottom: 0;
  border-bottom: 0;
} */
.normalsetsitem .normalsets-icon {
  max-width: 38px;
  max-height: 38px;
  width: 38px;
  width: 38px;
}

.setsitem-container.item-bought {
  margin-bottom: 15px;
}

.normalsetsitem .normalsets-content {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.normalsetsitem .normalsets-content > a:last-child {
  text-align: right;
}
.normalsets-content h5 {
  font-size: 17px;
}
.myDiscussion h3 {
  font-size: 25px;
  margin-bottom: 15px;
}

.myDiscussion ul {
  margin: 0;
  padding: 15px;
  background: #eae8fe;
  list-style: none;
  text-align: center;
  border-radius: 5px;
}
.myDiscussion p {
  font-size: 16px;
  font-weight: 600;
}
.myDiscussion ul li:not(:last-child) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myDiscussion ul li a {
  color: #111;
  font-weight: 600;
  font-size: 18px;
}

.myDiscussion ul li span {
  font-weight: bold;
  font-size: 14px;
}

.myDiscussion ul li a:hover {
  color: var(--secondary--color);
}

.discussionFields label {
  display: block;
  position: relative;
}

.discussionFields label p {
  position: absolute;
  right: 9px;
  top: 9px;
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  background: #fff;
}

.single__comment.comment__section > div > div > div > h3 {
  margin-bottom: 8px;
  margin-top: 15px;
}

.dashboard--card p {
  text-align: left;
}

.user-menu {
  position: relative;
}

.user-menu ul {
  position: absolute;
  right: 0;
  top: 0;
  width: 170px;
  z-index: 112;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  padding: 5px;
}

.user-menu ul li {
  font-size: 14px;
  font-weight: 500;
  color: #111;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eeee;
  cursor: pointer;
}

.user-menu ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.user-menu i {
  cursor: pointer;
  position: relative;
  z-index: 111;
}

.boughtSet .newTest::before {
  display: none;
}

.notverified-container h2 {
  font-size: 26px;
}

.grammar--share {
  position: relative;
}

/* .showShareButtons {
  position: absolute;
  left: -75px;
  bottom: -45px;
  width: 250px;
  background: #fff;
  padding: 5px;
} */

.showShareButtons > * {
  margin-left: 15px;
  vertical-align: middle;
}

.showShareButtons > button {
  padding: 10px !important;
  border: 1px solid rgb(229, 229, 229) !important;
  border-radius: 50px;
  font-size: 21px;
  width: 52px;
  display: inline-block;
  color: var(--primary--color);
}

.copyLink {
  margin-top: 15px;
  margin-left: 0;
  font-weight: 600;
  color: #555;
}

.copyLink > input {
  padding: 5px;
  font-size: 15px;
  display: block;
  width: 100%;
  outline: none;
  border: 1px solid rgb(229, 229, 229);
  text-align: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.copyLink > i {
  cursor: pointer;
  font-style: normal;
  margin-top: 6px;
  display: block;
  font-size: 14px;
  color: var(--primary--color);
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.viewAllLink {
  display: none;
}

.boughtSet .viewAllLink {
  display: block;
}

.dashboard--card {
  margin-bottom: 15px;
}

.otp__container button {
  display: block;
}

.otp__container button {
  display: block;
  /* width: auto; */
  margin: 0 auto;
  margin-top: 20px;
}

.otp__container button + p {
  display: inline-block;
}

.otp__container button.resendOtp {
  display: inline-block;
  border: none;
  outline: none;
  font-weight: bold;
  color: #222;
  background: transparent;
  margin: 0;
}

.buyPackages {
  padding: 15px 25px;
  background: var(--secondary--color);
  margin-bottom: 25px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.buyPackages.packagebought .buyBtn {
  background-color: rgb(0, 105, 0) !important;
}
.buyPackages.packagebought .buyBtn {
  color: #fff;
}
.buyPackages p {
  color: #fff;
}

.buyPackages > div:not(.pricepkg) {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.buyPackages:first-child {
  background-color: var(--primary--color);
}

.buyBtn {
  margin-top: 15px;
  display: inline-block;
  background: #fff;
  color: var(--secondary--color);
  font-size: 13px;
  font-weight: bold;
  padding: 8px 18px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: relative;
}

.buyPackages .text-end {
  position: relative;
  z-index: 111;
}

.buyBtn:hover {
  color: var(--primary--color);
}

.subPackages {
  margin: 15px 0;
  padding: 15px 0;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}

.subPackages span {
  margin-right: 8px;
}
.subPackages.boughtpackages ol {
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
}
.subPackages ol {
  padding: 0;
  margin: 10px 0;
  list-style: none;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}
.subPackages ol li {
  font-weight: 600;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #00437b1a;
}

.subPackages ol li h4:not(.btn),
.subPackages p {
  color: var(--secondary--color);
  font-weight: bold;
  font-size: 18px;
}

.mainPackageDetails p.pkgprice {
  margin: 10px 0;
}
.mainPackageDetails > p {
  font-size: 16px;
  font-weight: 600;
}
.mainPackageDetails span {
  margin-right: 8px;
  font-weight: bold;
}

.mainPackageDetails del {
  color: var(--primary--color);
  font-weight: bold;
}

.formDetail {
  margin-top: 15px;
}

.buyPackages p.packageCat {
  background: #fff;
  color: var(--secondary--color);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  padding: 5px 10px;
  margin-bottom: 7px;
  display: inline-block;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.buyPackages p.packageCat + span {
  display: block;
}

.mainPackageDetails {
  position: relative;
}

.buyPackages h6 {
  color: #fff;
}

.buyPackages.Platinum {
  background: #ada996;
  background: linear-gradient(to right, #e3e4df, #e5e5e5, #f5f7f2, #e5e4e2);
  background: -webkit-linear-gradient(
    to right,
    #eaeaea,
    #dbdbdb,
    #f2f2f2,
    #ada996
  );
  background: linear-gradient(to right, #eaeaea, #dbdbdb, #f2f2f2, #ada996);
}
.buyPackages.Gold {
  background: hsla(43, 58%, 51%, 1);
  /* background: linear-gradient(90deg, hsla(43, 58%, 51%, 1) 0%, hsla(56, 87%, 75%, 1) 47%, hsla(44, 61%, 55%, 1) 100%, hsla(44, 79%, 67%, 1) 100%);
  background: -moz-linear-gradient(90deg, hsla(43, 58%, 51%, 1) 0%, hsla(56, 87%, 75%, 1) 47%, hsla(44, 61%, 55%, 1) 100%, hsla(44, 79%, 67%, 1) 100%);
  background: -webkit-linear-gradient(90deg, hsla(43, 58%, 51%, 1) 0%, hsla(56, 87%, 75%, 1) 47%, hsla(44, 61%, 55%, 1) 100%, hsla(44, 79%, 67%, 1) 100%); */
  background: linear-gradient(
    90deg,
    hsla(43, 58%, 51%, 1) 0%,
    hsla(56, 87%, 75%, 1) 47%,
    hsla(44, 61%, 55%, 1) 100%,
    hsla(44, 79%, 67%, 1) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    hsla(43, 58%, 51%, 1) 0%,
    hsla(56, 87%, 75%, 1) 47%,
    hsla(44, 61%, 55%, 1) 100%,
    hsla(44, 79%, 67%, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    hsla(43, 58%, 51%, 1) 0%,
    hsla(56, 87%, 75%, 1) 47%,
    hsla(44, 61%, 55%, 1) 100%,
    hsla(44, 79%, 67%, 1) 100%
  );

  background: linear-gradient(to right, #a5782b, #f4d362, #fcf8b8, #e3c56d);
}

.buyPackages.Silver {
  background: #283048;
  background: -webkit-linear-gradient(
    to right,
    #d7d8d4,
    #c7c9c8,
    #b7b9bb,
    #a6aaaf,
    #969aa2,
    #868b96
  );
  background: linear-gradient(
    to right,
    #d7d8d4,
    #c7c9c8,
    #b7b9bb,
    #a6aaaf,
    #969aa2,
    #868b96
  );
}

.buyPackages span,
.buyPackages del {
  color: #252525;
}
.buyPackages.Platinum h6,
.buyPackages.Gold h6 {
  color: #252525;
}

.buyPackages.Platinum p,
.buyPackages.Gold p {
  color: #252525;
}

.comment__section > div > div > div > h3 input {
  border: none;
  border-bottom: 1px solid #111;
}

.userCommentDetail {
  margin-left: 15px;
  flex: 1 1;
}

.person-image img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50px;
}

.discussion-heads {
  margin-bottom: 25px;
}

.discussionTitle h3 {
  color: #111;
  margin-bottom: 50px;
}

.comment__section h5 {
  font-size: 18px;
  margin-bottom: 25px;
  text-align: center;
}

.commentSorting {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commentSorting form select {
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  border: 1px solid #33333352;
  padding: 8px 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.commentSorting input {
  width: 100%;
  padding: 8px 15px;
  outline: none;
  border: 1px solid #33333352;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.startDiscussion {
  position: relative;
  margin-bottom: 25px;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #eae8fe;
}

.startDiscussion p {
  font-size: 25px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 25px;
}

.startDiscussion h5 {
  text-align: center;
  font-size: 16px;
  padding: 15px 0;
  background: var(--secondary--color);
  color: #fff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  cursor: pointer;
}

.startDiscussion button {
  position: relative;
  z-index: 111;
}

.userCommentAll {
  flex: 1 1;
  margin-left: 25px;
}

.userMenu {
  margin-top: 8px;
}

.userMenu span {
  font-style: normal;
  margin-right: 8px;
  color: #333;
  font-size: 15px;
  cursor: pointer;
}

.userMenu span span {
  margin-left: 5px;
}

.userCommentAll > input {
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: none;
  border-bottom: 1px solid #33333352;
  padding: 5px 0;
  margin-bottom: 15px;
}

.contentArea {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px dashed var(--secondary--color);
}

.userCommentLvlOne {
  flex: 1;
}

.commentSorting form,
.discussionSearch {
  flex: 1;
}

.copyLink > input.copied {
  border: 2px solid green;
}

.dashboard__title h3 {
  color: #fff;
  font-weight: 550;
  text-transform: capitalize;
}

.regid {
  margin-bottom: 15px;
}
.regid label {
  margin-top: 15px;
  font-weight: 600;
  font-size: 16px;
}

.regid label p {
  font-size: 13px;
}

.insertimage h5 {
  margin-bottom: 15px;
  color: var(--secondary--color);
}

.userComment textarea {
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: 1px solid #dee0e1;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-bottom: 15px;
}

.userComment {
  margin: 0;
}

.userComments {
  padding: 0;
  border-top: none;
  background: transparent;
}

.userComments button {
  margin-bottom: 15px;
}

.addCommentBlog {
  padding: 25px;
  background: #f7f7f7;
}
.addCommentBlog h3 {
  margin-bottom: 15px;
  font-size: 25px;
}
.addCommentBlog .comment-details {
  padding: 15px 0;
  border: none;
  border-bottom: 1px solid #dee0e1;
  background: transparent;
}
.addCommentBlog .comment-details:last-child {
  border-bottom: 0;
}
.testscore {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 20px;
}

.testscore .percent {
  position: relative;
}

.testscore svg {
  position: relative;
}

.testscore .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}

.testscore .number h3 {
  font-weight: 200;
  font-size: 3.5rem;
}

.testscore .number h3 span {
  font-size: 2rem;
}

.testscore .title h2 {
  margin: 25px 0 0;
}

.footer-section > svg {
  top: -140px;
}

.dashboard--card.dashboardProfile {
  margin: 0;
}

.buyPackages .text-start {
  flex: 1 1;
}

.buyPackages .text-start p {
  font-size: 14px;
  font-weight: bold;
  color: #111;
}

.totalscore h5 {
  text-align: left;
  margin-bottom: 15px;
}

.totalRewardPoints {
  position: absolute;
  left: 37px;
  bottom: -2px;
  padding: 11px 25px;
  background: #fff;
  font-size: 20px;
  font-weight: bold;
}

.resultProgressBar {
  padding: 15px 20px;
  border: 1px solid #eee;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin: 0;
}

.resultUpdate > span img {
  width: 30px;
  vertical-align: sub;
  margin-left: 5px;
}

.resultUpdate > div > span img {
  width: 75px;
}

.resultUpdate {
  margin-top: -29px;
}

.resultUpdate > div > span {
  font-weight: bold;
}

.resultText > p {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  display: inline-block;
  position: relative;
}

.resultText > p::before {
  background: #fff url("../img/clock.png") 0 0 no-repeat;
  content: "";
  font-size: 25px;
  height: 25px;
  left: 50%;
  /* padding: 0 15px; */
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: 25px;
  background-size: contain;
}

.resultText > p span {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.bannerServices .eachservicecard svg {
  width: 290px;
  height: 370px;
}

.item-bought .btn {
  background: green;
}

.accordion-body {
  padding: 0;
}
.rightsidebar .btn {
  margin-bottom: 20px;
}
.rightsidebar .accordion {
  margin-bottom: 25px;
  padding: 0 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.rightsidebar .accordion-header button {
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  background: transparent;
}

.rightsidebar .accordion-item:last-child {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 15px;
}

.rightsidebar .accordion-collapse.collapse.show {
  margin-top: 20px;
}

.boughtSet .dashboard-page-title {
  margin: 0;
}

.btn-done {
  margin-right: 10px;
}

.comment-details .commentDetails .comment-details > div > div.d-flex {
  width: 100%;
}

.comment-details .commentDetails textarea {
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: 1px solid #dee0e1;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-top: 15px;
}

.commentUserMenu {
  margin-top: 15px;
}

.commentUserMenu > div {
  margin-right: 5px;
}

.purchasedSets .progressbar-container {
  margin: 0;
}

.eachdashboard-detail p.note-container,
.note-container {
  font-size: 15px;
  margin-top: 15px;
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  background-color: var(--secondary--color);
  color: #fff;
  box-shadow: 5px 5px 0px var(--primary--color);
}
.note-container p {
  color: #fff;
  margin-bottom: 0;
}

.resultUpdate .note-container {
  margin-bottom: 25px;
  margin-top: 0;
  display: inline-block;
}

.resultUpdate {
  margin-top: -29px;
  text-align: center;
}

.discount-percent {
  position: absolute;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  top: 0;
  right: 0;
  background-color: red;
}

.discount-percent p {
  color: #fff;
  font-size: 34px;
}

p.boughtat {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
}

.rewardNotice p {
  font-size: 18px;
  font-family: "Urbanist", sans-serif;
}

.rewardNotice h4 {
  font-size: 20px;
  color: var(--secondary--color);
  margin-top: 15px;
}

.resultUpdate .note-container p {
  font-weight: 500;
}

.resultUpdate .note-container i {
  margin-right: 5px;
}
.purchaseSuccess-modal h4 {
  font-size: 20px;
}
.purchaseSuccess-modal p {
  font-size: 17px;
  font-weight: 450;
}
.purchaseSuccess-modal p strong {
  font-size: 19px;
}

.dashboard-results__wrapper {
  position: relative;
}

.word-headertext {
  display: flex;
  gap: 15px;
}

.word-headertext i {
  font-size: 22px;
  color: #333;
}

.dashboard-page-title.greetinguser {
  font-size: 30px;
  margin-bottom: 25px;
}

.wordDay-collection > * {
  margin-bottom: 15px;
}
.wordDay-collection > *:last-child {
  margin-bottom: 0;
}

.package h3 {
  margin-bottom: 10px;
}
.prog {
  width: 100%;
}
.purchasedset-dashboard h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.purchasedset-dashboard h3 a {
  font-size: 14px;
  color: var(--secondary--color);
  font-weight: 500;
}
.dashboard-package-wrapper > h3 {
  margin-bottom: 15px;
}

.dashboard__right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationicon i {
  font-size: 26px;
  color: #fff;
  cursor: pointer;
}

.notificationicon {
  position: relative;
}

.notificationicon span {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -3px;
  right: -8px;
  background-color: red;
  color: #fff;
  font-size: 17px;
  border-radius: 100%;
}
.notificationContainer {
  background-color: #fff;
  border-radius: 8px;
  width: 380px;
}
.hovermenu-container ul.notificationContainer.hovermenu {
  left: -100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.no-notification {
  padding: 15px !important;
  text-align: center;
  font-weight: 550;
}
.notification-header {
  padding-bottom: 45px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.notificationwrapper {
  padding: 0;
}
.notificationlabels {
  padding: 15px;
  border-radius: 8px;
  background-color: #00447b17;
}
.notificationlabels ul {
  list-style: none;
  padding: 0;
}
.notificationlabels ul li {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
}
.notificationlabels ul li span {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 100%;
  display: inline-block;
}
.notificationlabels ul li span.read {
  background-color: #a6aaaf;
}
.notificationlabels ul li span.unread {
  background-color: #00437b1a;
}
.notificationwrapper li {
  list-style: none;
}
.notificationwrapper .eachnotification.notificationRead {
  border-bottom: 0;
}
.notificationwrapper .eachnotification {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.notificationwrapper .eachnotification:last-child {
  border-bottom: 0;
}
.readAllNotification {
  display: block;
  background-color: #00437b1a;
  padding: 17px 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--secondary--color);
  text-align: center;
}
.readAllNotification:hover {
  color: none;
}
.eachnotification {
  position: relative;
}
.eachnotification > div {
  display: flex !important;
  gap: 15px;
  padding: 7px;
  align-items: center;
}
.eachnotification .notificationimg img {
  border-radius: 100%;
  min-height: 54px;
  max-height: 54px;
  min-width: 54px;
  max-width: 54px;
}
.notificationRead {
  background-color: #00437b1a;
}
.notificationContent p {
  line-height: 1.25;
  color: #545;
  font-weight: 550;
}
.notificationContent p strong {
  font-weight: 650;
  color: #333;
}
.notificationContent .notificationDate {
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #8b8b8b;
}
.image-container.audioImg.played img {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  filter: blur(1px);
  -webkit-filter: blur(1px);
}
.image-container.audioImg.played {
  position: relative;
  pointer-events: none;
  cursor: not-allowed;
}
.copyLink > i.copied {
  color: green;
}

.youhavenot-container {
  background-color: #00437b1a;
  border-radius: 8px;
  padding: 25px 0;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

.youhavenot-container p {
  line-height: 1.35;
}

.contentArea p {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.5;
}

.userMenuOption-list {
  display: flex;
}
.userMenuOption-list div {
  flex: 0.5;
  text-align: center;
}

.userMenuOption-list div i {
  font-size: 20px;
}
.dictonarySidebar > ul > li > a.active {
  color: var(--secondary--color);
}

.notificationFormGroup .form-select {
  width: 20%;
  font-weight: 600;
}

.notificationFormGroup .form-control {
  width: 40%;
  font-weight: 600;
}

.nosearchresult {
  font-size: 20px;
  font-weight: 600;
}
.search {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.fullscreenLoader {
  background-color: var(--secondary--color);
}

.setstitem-container p.freetext {
  font-size: 16px;
}
.pkgprice {
  padding: 5px 35px;
  text-align: left;
  color: #fff;
  font-weight: 600;
  border-radius: 28px;
  background-color: var(--secondary--color);
  width: fit-content;
}

.btn-action-container {
  display: flex;
  justify-content: space-between;
}
.paymentoptions {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.paymentoptions .form-check {
  display: flex;
  align-items: center;
}
.labelImg img {
  width: 75px;
  height: 75px;
}
.labelImg {
  text-align: center;
}

.pleasewait {
  padding: 35px 0;
}
.pleasewait p {
  font-weight: 500;
}

.viewall {
  margin: 25px 0;
  padding: 35px;
  text-align: center;
  background-color: #eee;
}
.viewallbtn {
  position: relative;
  font-weight: 700;
  line-height: 1;
  font-size: 18px;
  display: inline-block;
  width: fit-content;
  margin-bottom: 25px;
  color: var(--secondary--color);
}
.viewallbtn:hover {
  color: var(--secondary--color);
}
.viewallbtn::after {
  content: " ";
  bottom: 0;
  left: 0;
  display: inline-block;
  height: 1px;
  transition: all 0.3s ease;
  background-color: #eae8fe;
  width: 50%;
}
.viewallbtn:hover::after {
  width: 100%;
}
.viewall p {
  font-size: 20px;
  font-weight: 600;
}
.custom-shape-divider-bottom-1678688493 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1678688493 svg {
  position: relative;
  display: block;
  width: calc(128% + 1.3px);
  height: 47px;
}

.custom-shape-divider-bottom-1678688493 .shape-fill {
  fill: #ffffff;
}
.about__wrapper {
  margin-bottom: 55px;
}
.ad-banner {
  display: block;
  margin-top: 25px;
}
.hideonmobile {
  display: block;
}
.showonmobile {
  display: none;
}
.reclaimbtn {
  position: relative;
}
.reclaimbtn .btn {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  background-color: #fff2d0;
  color: #222;
  font-weight: 650;
}
.reclaimbtn i {
  position: absolute;
  top: 50%;
  font-size: 15px;
  font-weight: 900;
  transform: translateY(-50%);
  color: #333;
  right: 10px;
}
.reclaimbtn p {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #f7f7f7;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 550;
  border-radius: 8px;
  padding: 15px;
  font-size: 16px;
  line-height: 1.25;
  animation: fadeup 0.3s ease forwards;
}
@keyframes fadeup {
  from {
    opacity: 0;
    top: 150%;
  }
  to {
    opacity: 1;
    top: 110%;
  }
}
.reclaimbtn:hover p {
  display: block;
}

.transactionStatusContainer p {
  line-height: 1.35;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
}

.transactionStatusContainer input {
  margin: 10px 0;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  border-bottom: 3px solid #c2c2c2;
  background-color: #eee;
}
.transactionStatusContainer label {
  font-size: 16px;
  font-weight: 500;
}
.transactionStatusContainer select {
  padding: 10px;
  background-color: #eee;
  border: none;
  outline: none;
}

.bigreclaim p {
  font-size: 16px;
  font-weight: 550;
  line-height: 1.25;
  color: var(--secondary--color);
  margin-bottom: 5px;
}

.eachbooknote img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  min-height: 250px;
  max-height: 200px;
  object-fit: cover;
}
.eachbooknote h3 {
  margin: 7px 0;
}
.eachbooknote h3 a {
  font-size: 20px;
  font-weight: 550;
  color: var(--secondary--color);
}

.eachbooknote h3 a:hover {
  color: var(--primary--color);
}
.eachbooknote p {
  font-size: 17px;
  font-weight: 550;
  line-height: 1.35;
  color: rgba(0, 0, 0, 0.4);
}

.setstitem-container > div.setsitem-container {
  width: 100%;
}

.setstitem-container > div.setsitem-container:nth-child(3n + 1),
.setstitem-container > div:nth-child(3n + 1) .setsitem-container, need
.subPackages ol li:nth-child(3n + 1),
.grammar--details > div:nth-child(3n + 1),
.comment__section > div:nth-child(3n + 1) {
  background: #eae8fe;
  border: none;
  border-radius: 5px;
}
.setstitem-container > div.setsitem-container:nth-child(3n + 2),
.setstitem-container > div:nth-child(3n + 2) .setsitem-container,
.subPackages ol li:nth-child(3n + 2),
.grammar--details > div:nth-child(3n + 2),
.comment__section > div:nth-child(3n + 2) {
  background: #fff2d0;
  border-radius: 5px;
  border: none;
}
.setstitem-container > div.setsitem-container:nth-child(3n + 3),
.setstitem-container > div:nth-child(3n + 3) .setsitem-container,
.subPackages ol li:nth-child(3n + 3),
.grammar--details > div:nth-child(3n + 3),
.comment__section > div:nth-child(3n + 3) {
  border-radius: 5px;
  border: none;
  background: #fddeee;
}

.ubtSetQuestions {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.ubtSetQuestions > div {
  flex: 45%;
}

.pdficon {
  display: flex;
  align-items: center;
  flex: 1;
}

.new__test {
  display: none;
}

.newTest .new__test {
  display: block;
  position: absolute;
  right: 10px;
  top: -11px;
  background: var(--primary--color);
  padding: 5px 10px;
  color: #fff;
  /* font-weight: 600; */
  border-radius: 15px;
  font-size: 10px;
  /* text-transform: uppercase; */
  font-family: "Poppins", sans-serif;
  line-height: 100%;
}

.ubtSetQuestions .btn {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.setsitem-container .pdficon img,
.normalsetsitem .pdficon img {
  padding: 7px;
  margin-right: 25px;
  background: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.ubt__boughtSet .setstitem-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.ubt__boughtSet .setstitem-container > div {
  flex: 30px;
  padding: 25px;
  border-radius: 15px;
}

.normalsets-content > a {
  flex: 3;
}

.normalsetsitem .normalsets-content > a:last-child {
  flex: 1;
  text-align: center;
}
.ubt__boughtSet {
  margin-bottom: 25px;
}

.ubt__boughtSet .setstitem-container .normalsetsitem:first-child {
  padding-top: 25px;
}

.item-bought .btn {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.ubtTest__section .setsitem-container {
  margin-bottom: 15px;
}

.sets-content > div:first-child {
  flex: 3;
  margin-right: 5px;
}

.sets-content > div.text-end {
  flex: 2;
}

/* .ubtSetQuestions .sets-content > div {
  flex: 4;
} */

.bigreclaim {
  margin-bottom: 15px;
  display: none;
}

.services-lists .setsitem-container {
  margin-bottom: 25px;
}

.hidesingle_g_d {
  display: none;
}

.grammar--details > div.goback {
  padding: 12px 30px;
  background: var(--secondary--color);
}

.singleselected.grammar--details > div:nth-child(3n + 2) {
  background: #fddeee;
}

.grammarDetails > div > div > div {
  flex: 1;
}

.grammarDetails h4 img {
  width: 20px;
}

.grammarDetails > div > div:last-child {
  margin: 0;
}

.mainblog-container > a span {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 5px 15px;
  background: #fddeee;
  color: #111;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.BlogDate p i {
  margin-right: 5px;
}
.blog-meta {
  display: flex;
  align-items: center;
}
.postBlog span {
  font-size: 15px;
  font-weight: 600;
  margin-right: 10px;
  color: #333;
}
.blog-date {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.postBlog img {
  width: 30px;
  margin-right: 5px;
}

.single-discussion {
  padding: 35px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #fddeee6b;
}

.books-items > div:not(:last-child) {
	border-bottom: 1px solid #f1f1f1;
	margin-bottom: 35px;
	padding-bottom: 35px;
}

.books-items > div > h3 {
  font-size: 22px;
  margin-bottom: 8px;
}

.books-items > div a.bookTitle {
  color: #111;
}

.anchor-text {
  font-weight: 900;
  color: var(--secondary--color);
  text-transform: uppercase;
  display: inline-block;
  padding-bottom: 10px;
  letter-spacing: 0.4px;
  font-size: 13.4px;
  position: relative;
}

.anchor-text:hover {
  color: var(--secondary--color);
}

.anchor-text::before {
  width: 20%;
  height: 2px;
  background: var(--secondary--color);
  content: "";
  position: absolute;
  transition: 0.3s linear;
  bottom: 0;
  left: 0;
}

.anchor-text:hover::before {
  width: 100%;
  transition: 0.3s linear;
  background: var(--secondary--color);
}

.mainblog-meta {
  display: flex;
  align-items: center;
  margin: 5px 0 10px 0;
}

.allcategory-container {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}
.allcategory-container li {
  background-color: #fff2d0;
  padding: 0 15px;
}
.allcategory-container li:last-child div {
  border-bottom: 0;
}
.allcategory-container li.active div {
  color: var(--secondary--color);
}
.allcategory-container li div {
  cursor: pointer;
  position: relative;
  padding: 15px 0;
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  display: block;
  text-align: left;
  background-color: transparent;
  font-weight: 600;
  font-size: 17px;
  color: #000000ba;
  border-bottom: 1px solid #0000002c;
  transition: all 0.3s ease;
}
.allcategory-container li div:hover {
  color: #222;
}

.allcategory-container li.active div::after,
.allcategory-container li div::after {
  content: "\F138";
  position: absolute;
  right: 25px;
  top: 50%;
  opacity: 0;
  transform: translateY(-50%);
  font-family: bootstrap-icons;
  font-weight: 900;
  font-size: 17px;
  transition: all 0.3s ease;
}
.allcategory-container li div:hover::after,
.allcategory-container li.active div::after {
  right: 0;
  opacity: 1;
}
.mainblog-meta .divider {
  margin: 0 15px;
  display: block;
  width: 1px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.2);
}

.mainblog-meta .viewers i {
  margin-right: 5px;
}

.goback.anchor-text {
  margin-bottom: 10px;
}
.mainblog-content .anchor-text{
  margin-top: 10px;
}

.selectedBlog-cat {
  font-weight: 600;
  color: var(--secondary--color);
  margin-bottom: 15px;
}

.newBookData .book__data {
  padding: 15px;
  display: flex;
}

.chapter__number {
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	font-weight: bold;
	color: var(--secondary--color);
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	margin-right: 25px;
	padding: 7px;
	min-width: 50px;
	height: 50px;
	min-height: 50px;
}

.chapter-content > div {
	flex: 1;
	margin-right: 25px;
}

.newBookData .row > div {
  margin-bottom: 25px;
}

.newBookData .row > div:nth-child(3n+1) .book__data {
  background: #eae8fe;
  border: none;
  border-radius: 5px;
}

.newBookData .row > div:nth-child(3n+2) .book__data {
  background: #fff2d0;
  border-radius: 5px;
  border: none;
}


.newBookData .row > div:nth-child(3n+3) .book__data {
  background: #fddeee;
  border-radius: 5px;
  border: none;
}
.chapter-content p {
  font-size: 14px;
  margin-top: 8px;
  line-height: 1.1;
}